// GalleryPreview.js
import {React} from 'react';
import { usePhotoContext } from './PhotoContext';
import { Link } from 'react-router-dom';

const GalleryPreview = () => {
  const { photoSets, isLoading  } = usePhotoContext();
  const CDN_BASE_URL = "https://catphonics.nyc3.cdn.digitaloceanspaces.com";
 
  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  return (
    <section className="galleryPreview">
      {photoSets.map((set) => (
        <Link key={set.id} to={`/set/${set.id}`}>
          <div className="set-preview">
            <div className="set-title">{set.title}</div>
            <img className="picture-thumbnail" src={`${CDN_BASE_URL}/${set.coverSrc}`} alt={set.title} />
          </div>
          </Link>
      ))}
    </section>
  );
};

export default GalleryPreview;