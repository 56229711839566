import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePhotoContext } from "./PhotoContext";

const PhotoSet = () => {
  const {
    selectedSet,
    selectedPhoto,
    selectPhoto,
    selectSet,
    currentPage,
    itemsPerPage,
    changePage,
    isLoading,
    isOverlayVisible,
    setIsOverlayVisible,
    getNextPhoto,
    getPreviousPhoto
  } = usePhotoContext();
  const { setId } = useParams(); // Extracts the setId parameter from the URL
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const CDN_PHOTO_URL = "https://catphonics.nyc3.cdn.digitaloceanspaces.com";


  useEffect(() => {
    selectSet(setId);
  }, [setId, selectSet]);

  useEffect(() => {
    // When selectedSet changes, and if there's no selectedPhoto or it's the wrong set, update selectedPhoto
    if (selectedSet && selectedSet.photos.length > 0 && (!selectedPhoto || selectedPhoto.setId!== selectedSet.id)) {
      selectPhoto(selectedSet.photos[0]); // Update selectedPhoto to the first photo of the new set
    }
  }, [selectedSet]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          getPreviousPhoto();
          break;
        case "ArrowRight":
        case " ":
          getNextPhoto();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [getPreviousPhoto, getNextPhoto]);


  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };



  if (!selectedSet) {
    return null;
  }


  return (
    <article>
      <section className="photoSelector">
        <div className="post">
          <div className="current-set">
            <div className="current-set-title">{selectedSet.title}</div>
            <img
              className="current-set-cover"
              src={`${CDN_PHOTO_URL}/${selectedSet.coverSrc}`}
              alt={selectedSet.title}
            />
            <p>{selectedSet.description}</p>
            <div className="pagination-controls">
              <button
                disabled={currentPage === 1}
                onClick={() => changePage(currentPage - 1)}
              >
                Prev
              </button>
              <span>
                Page {currentPage} of{" "}
                {Math.ceil(selectedSet.photos.length / itemsPerPage)}
              </span>
              <button
                disabled={
                  currentPage ===
                  Math.ceil(selectedSet.photos.length / itemsPerPage)
                }
                onClick={() => changePage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
          <div className="set-photos">
            {selectedSet.photos
              .slice(startIndex, endIndex)
              .map((photo, index) => (
                <a
                  key={index}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    selectPhoto(photo); // Now selectPhoto is defined and can be used here
                  }}
                >
                  <img
                    className="thumbnail-mini"
                    src={`${CDN_PHOTO_URL}/${photo.thumbSrc}`}
                    alt={`Thumbnail ${index + 1}`}
                  />
                </a>
              ))}
          </div>
        </div>
      </section>
      <section className="selectedViewer">
      <div className="prevNextbuttons">
          <button className="photoNav" onClick={getPreviousPhoto}>
            previous
          </button>
          <button className="photoNav" onClick={getNextPhoto}>
            next
          </button>
          </div>
        {/* Overlay Icon */}
        <div className="photo-container">
          {isOverlayVisible && (
            <div className="overlay-full">
              <p>Camera: Nikon D90</p>
              <p> {selectedPhoto?.date} </p>
              <p>{selectedPhoto?.title}</p>
            </div>
          )}
          <img
            className="selectedPhoto"
            src={`${CDN_PHOTO_URL}/${selectedPhoto.src || selectedSet.coverSrc}`}
          />
          <div className={"icon-overlay"} onClick={toggleOverlay}>
            ?
          </div>
        </div>
        
      </section>
      
    </article>
  );
};

export default PhotoSet;
