// src/PhotoContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const PhotoContext = createContext();

export const usePhotoContext = () => useContext(PhotoContext);

export const PhotoProvider = ({ children }) => {
  const [selectedPhoto, setSelectedPhoto] = useState({ src: "", title: "" });
  const [selectedSet, setSelectedSet] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [itemsPerPage, setItemsPerPage] = useState(32); // Items per page state
  const [photoSets, setPhotoSets] = useState([]);
  const [setDetails, setSetDetails] = useState({}); // State to hold set details
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const CDN_PHOTO_URL = "https://catphonics.nyc3.cdn.digitaloceanspaces.com";
  const CDN_BASE_URL = "https://catphonics.nyc3.cdn.digitaloceanspaces.com";


  useEffect(() => {
    // Fetch initial sets
    fetch(`${CDN_BASE_URL}/photoSets.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async (initialData) => {
        setPhotoSets(initialData);
        // Preload set details
        const preloadSetDetails = initialData.forEach(async (set) => {
          try {
            const response = await fetch(`${CDN_PHOTO_URL}/${set.photoset}`);
            const detailData = await response.json();
            setSetDetails((prevDetails) => ({
              ...prevDetails,
              [set.id]: detailData,
            }));
          } catch (error) {
            console.error(`Failed to load details for set ${set.id}:`, error);
          }
        });

        // Wait for all set details to load
        await Promise.all(preloadSetDetails);

        // Update the state with preprocessed data
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching photo sets:", error);
        setIsLoading(false);
      });
  }, []);

  const selectPhoto = (photoObj) => {
    setSelectedPhoto(photoObj);
  };

  const selectSet = async (setId) => {
    const selectedSet = photoSets.find((set) => set.id === setId);
    if (selectedSet) {
      const setDetail = setDetails[setId];
      setSelectedSet(setDetail);
      setIsLoading(false);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const getNextPhoto = () => {
    const currentIndex = selectedSet.photos.findIndex(
      (photo) => photo.src === selectedPhoto.src
    );
    const nextIndex = (currentIndex + 1) % selectedSet.photos.length;
    setSelectedPhoto(selectedSet.photos[nextIndex]);
  };

  const getPreviousPhoto = () => {
    const currentIndex = selectedSet.photos.findIndex(
      (photo) => photo.src === selectedPhoto.src
    );
    const prevIndex =
      (currentIndex - 1 + selectedSet.photos.length) %
      selectedSet.photos.length;
    setSelectedPhoto(selectedSet.photos[prevIndex]);
  };

  return (
    <PhotoContext.Provider
      value={{
        selectedPhoto,
        selectPhoto,
        selectedSet,
        selectSet,
        currentPage,
        itemsPerPage,
        changePage,
        photoSets,
        setPhotoSets,
        isLoading,
        isOverlayVisible,
        setIsLoading,
        setIsOverlayVisible,
        setItemsPerPage,
        getNextPhoto,
        getPreviousPhoto,
      }}
    >
      {children}
    </PhotoContext.Provider>
  );
};
