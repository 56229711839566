// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css'; // Adjust the path as necessary
import { PhotoProvider } from './PhotoContext';
import GalleryPreview from './GalleryPreview';
import PhotoSet from './PhotoSet';

const App = () => {
  return (
    <Router>
    <PhotoProvider>

       <h1>WAKINGIDEAS | <Link to="/">PHOTOSETS</Link> </h1>
      <Routes>
          <Route path="/" element={<GalleryPreview />} />
          {/* Assuming you have a way to identify each set by a unique ID in the URL */}
          <Route path="/set/:setId" element={<PhotoSet />} />
        </Routes>
    </PhotoProvider>
    </Router>

  );
};

export default App;
